import { NavItem, PageNavigation } from 'models';
import { HAD_PATHS } from 'reports/had/constants/had.constant';
import { cwRoutePrefix, PerformancePath } from '../reports/cw/constants/cw.constant';

export const integrationLoginSuffix = '/login';
export const appLinkingLoginSuffix = '/redirect';

export const RoutePath = {
  login: `**${integrationLoginSuffix}`,
  appLinkingLogin: `**${appLinkingLoginSuffix}`,
  loginPath: '/login-service/login',
  userServicePath: '/apiv1/eolsUser/list',
  tokenServicePath: '/token-service',
  home: '/',
  cwIns: `${cwRoutePrefix.instructor}/home`,
  cwStu: `${cwRoutePrefix.student}/home`,
  had: '/hesiDashboard',
  ehr: '/eaqHesiReadiness',
  student: {
    home: '/student'
  },
  reportingPortal: '/reportingPortal'
};

export const PageNamePrefixes = {
  cw: 'Elsevier Performance Reports',
  ehr: 'EAQ HESI Readiness Dashboard',
  had: 'HESI Dashboard',
  sh: 'Shadow Health Dashboard',
  erp: 'Elsevier Reporting Portal',
  ps: 'Program Success Dashboard'
};

export const AppConstant = {
  PERSIST_KEY: 'globalPerformanceReportState',
  reduxResources: {
    ROUTER: 'router',
    APP_STATE: 'appState',
    LANGUAGE_STATE: 'languageState',
    FILTER_STATE: 'filterState',
    CW_STATE: 'cwState',
    COURSES_STATE: 'coursesState',
    HAD_STATE: 'hadState',
    EHR_STATE: 'ehrState',
    SH_STATE: 'shState',
    PS_STATE: 'psState',
    HESI_NG_STATE: 'hesiNGState'
  }
};

export const FeatureFlagNames = {
  APP_LINK_ASSIGNMENT_MANAGEMENT_ENABLE: 'APP_LINK_ASSIGNMENT_MANAGEMENT_ENABLE',
  CW_ACTIVE_COURSE_ENABLED: 'CW_ACTIVE_COURSE_ENABLED',
  CW_ASSIGNMENT_DETAIL_APP_LINK_ENABLED: 'CW_ASSIGNMENT_DETAIL_APP_LINK_ENABLED',
  CW_ASSIGNMENT_DETAIL_LINK_TO_EAB_ENABLED: 'CW_ASSIGNMENT_DETAIL_LINK_TO_EAB_ENABLED',
  CW_ASSIGNMENT_DETAIL_LINK_TO_OSMOSIS_ENABLED: 'CW_ASSIGNMENT_DETAIL_LINK_TO_OSMOSIS_ENABLED',
  CW_ASSIGNMENT_DETAIL_LINK_TO_SHER_EVOL_ENABLED: 'CW_ASSIGNMENT_DETAIL_LINK_TO_SHER_EVOL_ENABLED',
  CW_CLARITY_DATA_ICON_ENABLED: 'CW_CLARITY_DATA_ICON_ENABLED',
  CW_DATA_MASKING_ENABLED: 'CW_DATA_MASKING_ENABLED',
  CW_EAQ_TOPIC_REPORT_ENABLED: 'CW_EAQ_TOPIC_REPORT_ENABLED',
  CW_GRADE_WEIGHTING_SCORE_ENABLED: 'CW_GRADE_WEIGHTING_SCORE_ENABLED',
  CW_GRADE_WEIGHTING_SCORE_ENABLED_IN_GRADEBOOK: 'CW_GRADE_WEIGHTING_SCORE_ENABLED_IN_GRADEBOOK',
  CW_HAD_LINK_ENABLED: 'CW_HAD_LINK_ENABLED',
  CW_HAD_PRD_LINK_ENABLED: 'CW_HAD_PRD_LINK_ENABLED',
  CW_HESI_READINESS_PAGE_ENABLED: 'CW_HESI_READINESS_PAGE_ENABLED',
  CW_HIDE_GRADE_BOOK_FILTER_DROP_DOWN: 'CW_HIDE_GRADE_BOOK_FILTER_DROP_DOWN',
  CW_HIDE_INCORRECT_DATA: 'CW_HIDE_INCORRECT_DATA',
  CW_HIDE_STUDENT_DETAIL_CHART: 'CW_HIDE_STUDENT_DETAIL_CHART',
  CW_PERFORMANCE_COMPARED_TO_CLASS_AVERAGE_HIDDEN: 'CW_PERFORMANCE_COMPARED_TO_CLASS_AVERAGE_HIDDEN',
  CW_STUDENT_HOME_EAQ_TOPIC_REPORT_LINK_ENABLED: 'CW_STUDENT_HOME_EAQ_TOPIC_REPORT_LINK_ENABLED',
  CW_STUDENT_HOME_TOPIC_IN_NOVICE_OR_BELOW_ENABLED: 'CW_STUDENT_HOME_TOPIC_IN_NOVICE_OR_BELOW_ENABLED',
  DWH_STUDENT_DETAIL_BY_PROGRAM_API_ENABLED: 'DWH_STUDENT_DETAIL_BY_PROGRAM_API_ENABLED',
  DWH_STUDENT_EXAM_CATEGORY_API_ENABLED: 'DWH_STUDENT_EXAM_CATEGORY_API_ENABLED',
  DWH_STUDENT_EXAM_CATEGORY_BY_PROGRAM_API_ENABLED: 'DWH_STUDENT_EXAM_CATEGORY_BY_PROGRAM_API_ENABLED',
  DWH_STUDENT_SUMMARY_AND_STUDENT_EXAMS_API_ENABLED: 'DWH_STUDENT_SUMMARY_AND_STUDENT_EXAMS_API_ENABLED',
  ENABLE_STUDENT_CAN_CREATE_ASSIGNMENT: 'ENABLE_STUDENT_CAN_CREATE_ASSIGNMENT',
  ENABLE_STUDENT_STUDY_REDIRECT: 'ENABLE_STUDENT_STUDY_REDIRECT',
  EHR_CLARITY_DATA_ICON_ENABLED: 'EHR_CLARITY_DATA_ICON_ENABLED',
  EHR_CREATE_QUIZ_BUTTON_ENABLED: 'EHR_CREATE_QUIZ_BUTTON_ENABLED',
  EHR_STUDENT_DETAIL_PAGE_ENABLED: 'EHR_STUDENT_DETAIL_PAGE_ENABLED',
  EHR_STUDENT_TAB_ENABLED: 'EHR_STUDENT_TAB_ENABLED',
  EHR_SUB_NAV_ENABLED: 'EHR_SUB_NAV_ENABLED',
  EVOLVE_ID_ON_ANALYTICS_ENABLED: 'EVOLVE_ID_ON_ANALYTICS_ENABLED',
  HAD_CATEGORY_GROUP_DWH_ENABLE: 'HAD_CATEGORY_GROUP_DWH_ENABLE',
  HAD_CATEGORY_STATE_BASED_EXAM_ENABLED: 'HAD_CATEGORY_STATE_BASED_EXAM_ENABLED',
  HAD_CATEGORY_SUMMARY_DWH_ENABLED: 'HAD_CATEGORY_SUMMARY_DWH_ENABLED',
  HAD_CLARITY_DATA_ICON_ENABLED: 'HAD_CLARITY_DATA_ICON_ENABLED',
  HAD_COHORT_CATEGORY_DWH_ENABLED: 'HAD_COHORT_CATEGORY_DWH_ENABLED',
  HAD_COHORT_CATEGORY_TAB_ENABLED: 'HAD_COHORT_CATEGORY_TAB_ENABLED',
  HAD_COHORT_DETAIL_DWH_ENABLED: 'HAD_COHORT_DETAIL_DWH_ENABLED',
  HAD_COHORT_TRENDLINE_DWH_ENABLED: 'HAD_COHORT_TRENDLINE_DWH_ENABLED',
  HAD_CW_LINK_ENABLED: 'HAD_CW_LINK_ENABLED',
  HAD_CW_NEW_HEADER_ENABLE: 'HAD_CW_NEW_HEADER_ENABLE',
  HAD_EXAM_CATEGORY_DWH_ENABLED: 'HAD_EXAM_CATEGORY_DWH_ENABLED',
  HAD_EXAM_DETAIL_PAGE_ENABLED: 'HAD_EXAM_DETAIL_PAGE_ENABLED',
  HAD_NGN_ENABLE_COHORTCARDNGN: 'HAD_NGN_ENABLE_COHORTCARDNGN',
  HAD_NGN_ENABLE_COHORTLEVELNGN: 'HAD_NGN_ENABLE_COHORTLEVELNGN',
  HAD_NGN_ENABLE_EXAMLEVELNGN: 'HAD_NGN_ENABLE_EXAMLEVELNGN',
  HAD_NGN_ENABLE_NEW_SCORING: 'HAD_NGN_ENABLE_NEW_SCORING',
  HAD_NGN_ENABLE_STUDENTLEVELNGN: 'HAD_NGN_ENABLE_STUDENTLEVELNGN',
  HAD_NGN_LINK_OUT_ENABLED: 'HAD_NGN_LINK_OUT_ENABLED',
  HAD_PN_PROGRAM_HIDDEN: 'HAD_PN_PROGRAM_HIDDEN',
  HAD_STUDENT_DETAIL_LINK_CROSS_APP_ENABLED: 'HAD_STUDENT_DETAIL_LINK_CROSS_APP_ENABLED',
  HAD_STUDENT_DETAIL_MULTIPLE_COHORT_ENABLED: 'HAD_STUDENT_DETAIL_MULTIPLE_COHORT_ENABLED',
  HAD_STUDENT_DETAIL_PAGE_ENABLED: 'HAD_STUDENT_DETAIL_PAGE_ENABLED',
  HAD_STUDENT_SUMMARY_NEW_TABLE: 'HAD_STUDENT_SUMMARY_NEW_TABLE',
  HAD_STUDENT_TRENDLINE_BY_PROGRAM_DWH_ENABLED: 'HAD_STUDENT_TRENDLINE_BY_PROGRAM_DWH_ENABLED',
  HAD_STUDENT_TRENDLINE_DWH_ENABLED: 'HAD_STUDENT_TRENDLINE_DWH_ENABLED',
  HAD_STUDENT_VIEW_MULTI_PROGRAM: 'HAD_STUDENT_VIEW_MULTI_PROGRAM',
  HESI_EXAMRESULTS_CATEGORY_DRILLDOWN: 'HESI_EXAMRESULTS_CATEGORY_DRILLDOWN',
  HESI_EXAMRESULTS_LEGAL_DISCLAIMER: 'HESI_EXAMRESULTS_LEGAL_DISCLAIMER',
  HESI_EXAMRESULTS_MULTIPLEATTEMPTSINDICATOR: 'HESI_EXAMRESULTS_MULTIPLEATTEMPTSINDICATOR',
  HESI_NEEDSFURTHERPREPARATION_LABELUPDATE: 'HESI_NEEDSFURTHERPREPARATION_LABELUPDATE',
  HESI_NG_HIDE_ITEM_DETAILS_TABLE: 'HESI_NG_HIDE_ITEM_DETAILS_TABLE',
  HESI_NG_STUDENT_FACING_NEW_CATEGORY_API_ENABLED: 'HESI_NG_STUDENT_FACING_NEW_CATEGORY_API_ENABLED',
  HESI_A2_EXAM_RESULTS_ENABLED: 'HESI_A2_EXAM_RESULTS_ENABLED',
  NGN_SUMMARY_API_ENABLED: 'NGN_SUMMARY_API_ENABLED',
  PRD_COURSE_AGGREGATE: 'PRD_COURSE_AGGREGATE',
  PRD_CLARITY_DATA_ICON_ENABLED: 'PRD_CLARITY_DATA_ICON_ENABLED',
  PRD_ENABLE_ASSIGNMENT_DETAIL: 'PRD_ENABLE_ASSIGNMENT_DETAIL',
  PRD_ENABLE_ASSIGNMENT_DETAIL_LINKING: 'PRD_ENABLE_ASSIGNMENT_DETAIL_LINKING',
  PRD_ENABLE_CARE_PLAN: 'PRD_ENABLE_CARE_PLAN',
  PRD_ENABLE_CJMM_ASSIGNMENT_DETAIL: 'PRD_ENABLE_CJMM_ASSIGNMENT_DETAIL',
  PRD_ENABLE_CJMM_COURSE_LEVEL: 'PRD_ENABLE_CJMM_COURSE_LEVEL',
  PRD_ENABLE_CJMM_STUDENT_DETAIL: 'PRD_ENABLE_CJMM_STUDENT_DETAIL',
  PRD_ENABLE_EDUCATION_EMPATHY: 'PRD_ENABLE_EDUCATION_EMPATHY',
  PRD_ENABLE_STUDENT_DETAIL_ASSIGNMENT_LINKS: 'PRD_ENABLE_STUDENT_DETAIL_ASSIGNMENT_LINKS',
  PRD_ENABLE_STUDENT_PROFILE: 'PRD_ENABLE_STUDENT_PROFILE',
  PRD_ENABLE_STUDENT_PROFILE_LINKING: 'PRD_ENABLE_STUDENT_PROFILE_LINKING',
  PRD_HIDE_INSTRUCTOR_DROPDOWN: 'PRD_HIDE_INSTRUCTOR_DROPDOWN',
  PRD_PRODUCT_DEMO_INSTRUCTOR_ID: 'PRD_PRODUCT_DEMO_INSTRUCTOR_ID',
  PS_ASSOCIATE_DETAILS_ENABLED: 'PS_ASSOCIATE_DETAILS_ENABLED',
  PS_CLARITY_DATA_ICON_ENABLED: 'PS_CLARITY_DATA_ICON_ENABLED',
  PS_EAQ_TOPIC_MASTERY_ADDITIONAL_RESOURCES_ENABLED: 'PS_EAQ_TOPIC_MASTERY_ADDITIONAL_RESOURCES_ENABLED',
  PS_NGN_ENABLE_NEW_SCORING: 'PS_NGN_ENABLE_NEW_SCORING',
  PS_SUPPORT_BUTTON_ENABLED: 'PS_SUPPORT_BUTTON_ENABLED',
  PS_TAP_SH_CATEGORY_ENABLED: 'PS_TAP_SH_CATEGORY_ENABLED',
  TSP_GATEWAY_LINK_FEATURE_ENABLED: 'TSP_GATEWAY_LINK_FEATURE_ENABLED',
  PERFROMANCE_DEMO_APP_ENABLED: 'PERFORMANCE_DEMOAPP_ENABLED',
  ENABLE_MOCK_SERVICE_WORKER: 'ENABLE_MOCK_SERVICE_WORKER'
};

export const FeatureFlagGroupNames = {
  HAD_PN_PROGRAM_HIDDEN_GROUP: 'HAD_PN_PROGRAM_HIDDEN_GROUP'
};

export const CW_ENGAGEMENT_CARD_ID = {
  classAverageScore: 'CW_CLASS_AVERAGE_SCORE',
  assignmentCompleted: 'CW_ASSIGNMENT_COMPLETED',
  numberOfStudentsWithPastDue: 'CW_NUMBER_OF_STUDENTS_WITH_PAST_DUE'
};

export const CW_INSTRUCTOR_HOME_CARD_ID = {
  assignmentCompleted: 'CW_INSTRUCTOR_HOME_COMPLETED_ASSIGNMENTS',
  aboveClassAvg: 'CW_INSTRUCTOR_HOME_ABOVE_CLASS_AVG',
  disengagedStudents: 'CW_DISENGAGED_STUDENTS'
};

export const ANALYTIC_FEATURES = {
  CW_INSTRUCTOR_HOME_VIEW_MY_COURSE_PLAN_CLICK: 'cw-instructor-home__view_my_course_plan--click',
  CW_INSTRUCTOR_HOME_VIEW_ALL_COURSES_CLICK: 'cw-instructor-home__view_all_courses--click',
  CW_INSTRUCTOR_HOME_TAKE_A_TOUR_CLICK: 'cw-instructor-home__take-a-tour--click',
  CW_ENGAGEMENT_STUDENT_TAB_VIEW: 'cw-engagement__student-tab--view',
  CW_ENGAGEMENT_ASSIGNMENT_TAB_VIEW: 'cw-engagement__assignment-tab--view',
  CW_ASSIGNMENT_SUMMARY_GRADED_ASSIGNMENTS_ONLY_ENABLE: 'cw_assignment_summary-graded-assignments-only--enable',
  CW_ASSIGNMENT_SUMMARY_GRADED_ASSIGNMENTS_ONLY_DISABLE: 'cw_assignment_summary-graded-assignments-only--disable',
  CW_STUDENT_HOME_ASSIGNMENT_SUMMARY_TAB_VIEW: 'cw-student-home__assignment-summary-tab--view',
  CW_STUDENT_HOME_ASSIGNMENT_SUMMARY_GRADED_ASSIGNMENTS_ONLY_ENABLE: 'cw-student-home__assignment-summary-graded-assignments-only--enable',
  CW_STUDENT_HOME_ASSIGNMENT_SUMMARY_GRADED_ASSIGNMENTS_ONLY_DISABLE: 'cw-student-home__assignment-summary-graded-assignments-only--disable',
  CW_STUDENT_HOME_ASSIGNMENT_ENGAGEMENT_TAB_VIEW: 'cw-student-home__assignment-engagement-tab--view',
  CW_STUDENT_HOME_ASSIGNMENT_ENGAGEMENT_GRADED_ASSIGNMENTS_ONLY_ENABLE: 'cw-student-home__assignment-engagement-graded-assignments-only--enable',
  CW_STUDENT_HOME_ASSIGNMENT_ENGAGEMENT_GRADED_ASSIGNMENTS_ONLY_DISABLE: 'cw-student-home__assignment-engagement-graded-assignments-only--disable',
  CW_STUDENT_HOME_EAQ_TOPIC_REPORT_CLICK: 'cw-student-home__eaq-topic-report--click',
  CW_STUDENT_HOME_CREATE_QUIZ_BUTTON_CLICK: 'cw-student-home__create-quiz-button--click',
  CW_COURSE_AGGREGATE_COURSE_SEARCH: 'cw-course-aggregate__search',
  CW_COURSE_AGGREGATE_COURSE_SORT_BY_CLICK: 'cw-course-aggregate__sort-by--click',
  CW_COURSE_AGGREGATE_ACTIVE_COURSE_ONLY_ENABLE: 'cw-course-aggregate__active_course_only--enable',
  CW_COURSE_AGGREGATE_ACTIVE_COURSE_ONLY_DISABLE: 'cw-course-aggregate__active_course_only--disable',

  CW_STUDENT_DETAIL_ASSIGNMENT_SUMMARY_TAB_VIEW: 'cw-student-detail__assignment-summary-tab--view',
  CW_STUDENT_DETAIL_ASSIGNMENT_SUMMARY_GRADED_ASSIGNMENTS_ONLY_ENABLE: 'cw-student-detail__assignment-summary-graded-assignments-only--enable',
  CW_STUDENT_DETAIL_ASSIGNMENT_SUMMARY_GRADED_ASSIGNMENTS_ONLY_DISABLE: 'cw-student-detail__assignment-summary-graded-assignments-only--disable',
  CW_STUDENT_DETAIL_ASSIGNMENT_ENGAGEMENT_TAB_VIEW: 'cw-student-detail__assignment-engagement-tab--view',
  CW_STUDENT_DETAIL_ASSIGNMENT_ENGAGEMENT_GRADED_ASSIGNMENTS_ONLY_ENABLE: 'cw-student-detail__assignment-engagement-graded-assignments-only--enable',
  CW_STUDENT_DETAIL_ASSIGNMENT_ENGAGEMENT_GRADED_ASSIGNMENTS_ONLY_DISABLE: 'cw-student-detail__assignment-engagement-graded-assignments-only--disable',
  CW_STUDENT_DETAIL_ASSIGNMENT_ABOVE_AVG_CLICK: 'cw-student-detail__assignment-above-avg--click',
  CW_STUDENT_DETAIL_EAQ_TOPIC_REPORT_CLICK: 'cw-student-detail__eaq-topic-report--click',
  CW_STUDENT_DETAIL_LINK_HAD_STUDENT_DETAIL_CLICK: 'cw-student-detail__link-had-student-detail--click',
  [CW_ENGAGEMENT_CARD_ID.classAverageScore]: 'cw-engagement__class-average-score--click',
  [CW_ENGAGEMENT_CARD_ID.assignmentCompleted]: 'cw-engagement__assignment-completed--click',
  [CW_ENGAGEMENT_CARD_ID.numberOfStudentsWithPastDue]: 'cw-engagement__past-due-students--click',
  CW_STUDENT_HOME_EAQ_TOPIC_REPORT_VIEW: 'cw-student-home__eaq-topic-report--view',

  EHR_BY_TOPIC_AND_CHAPTER_VIEW: 'ehr__topic-and-chapter-tab--view',
  EHR_BY_STUDENT_VIEW: 'ehr__student-tab--view',
  EHR_SUBMISSIONS_STUDENTS_CLICK: 'ehr__zero-submissions-students--click',
  EHR_BELOW_NOVICE_STUDENTS_CLICK: 'ehr__below-novice-students--click',

  HAD_COHORT_DETAIL_BY_EXAM_VIEW: 'had-cohort-detail__exam-tab--view',

  HAD_COHORT_DETAIL_BY_STUDENT_VIEW: 'had-cohort-detail__student-tab--view',
  HAD_COHORT_DETAIL_AT_RISK_CLICK: 'had-cohort-detail__at-risk--click',
  HAD_COHORT_DETAIL_BELOW_ACCEPTABLE_CLICK: 'had-cohort-detail__below-acceptable--click',
  HAD_COHORT_DETAIL_ACCEPTABLE_CLICK: 'had-cohort-detail__acceptable--click',
  HAD_COHORT_DETAIL_RECOMMENDED_CLICK: 'had-cohort-detail__recommended--click',

  HAD_COHORT_DETAIL_BY_CATEGORY_VIEW: 'had-cohort-detail__category-tab--view',
  HAD_COHORT_DETAIL_CATEGORY_CARD_CLICK: 'had-cohort-detail__category-card--click',

  HAD_STUDENT_DETAIL_BY_CATEGORY_VIEW: 'had-student-detail__category-tab--view',
  HAD_STUDENT_DETAIL_BY_EXAM_VIEW: 'had-student-detail__exam-tab--view',
  HAD_STUDENT_DETAIL_BY_MONTH_VIEW: 'had-student-detail__month-tab--view',
  HAD_STUDENT_DETAIL_BY_NGN_VIEW: 'had-student-detail__ngn-tab--view',
  HAD_STUDENT_DETAIL_LINK_CW_STUDENT_DETAIL_CLICK: 'had-student-detail__link-cw-student-detail--click',

  HAD_STUDENT_HOME_BY_EXAM_VIEW: 'had-student-home__exam-tab--view',
  HAD_STUDENT_HOME_BY_CATEGORY_VIEW: 'had-student-home__category-tab--view',
  HAD_STUDENT_HOME_BY_MONTH_VIEW: 'had-student-home__month-tab--view',
  HAD_STUDENT_HOME_BY_NGN_VIEW: 'had-student-home__ngn-tab--view',

  HAD_COHORT_DETAIL_BY_MONTH_VIEW: 'had-cohort-detail__month-tab--view',

  HAD_EXAM_DETAIL__VIEW_STUDENT_RESULTS_NGN_CLICK: 'had-exam-detail__view-students-results-on-items-for-NGN--click',

  HAD_SELECT_NGN_COURSE_DIALOG__BACK_TO_EXAMS_RESULT_CLICK: 'had-select-ngn-course-dialog__back-to-exams-results--click',
  HAD_SELECT_NGN_COURSE_DIALOG__VIEW_STUDENT_RESULT_ON_NGN_ITEM_CLICK: 'had-select-ngn-course-dialog__view-student-results-on-ngn-item--click',

  PRD_DASHBOARD_STUDENT_PERFORMANCE_VIEW: 'prd-dashboard__student-performance--view',
  PRD_DASHBOARD_STUDENT_ENGAGEMENT_VIEW: 'prd-dashboard__student-engagement--view',
  PRD_DASHBOARD_ASSIGNMENT_SUMMARY_VIEW: 'prd-dashboard__assignment-summary--view',
  PRD_DASHBOARD_CATEGORY_STRENGTHS_AND_WEAKNESSES_VIEW: 'prd-dashboard__category-strengths-and-weaknesses--view',
  PRD_DASHBOARD_LOW_PERFORMING_STUDENT_CLICK: 'prd-dashboard__low-performing-student--click',
  PRD_DASHBOARD_LOW_PATIENT_INTERACTION_TIME_CLICK: 'prd-dashboard__low-patient-interaction-time--click',
  PRD_DASHBOARD_SUBJECTIVE_DATA_COLLECTION_CLICK: 'prd-dashboard__subjective-data-collection--click',
  PRD_DASHBOARD_OBJECTIVE_DATA_COLLECTION_CLICK: 'prd-dashboard__objective-data-collection--click',
  PRD_DASHBOARD_EDUCATION_AND_EMPATHY_CLICK: 'prd-dashboard__education-and-empathy--click',
  PRD_DASHBOARD_CARE_PLAN_CLICK: 'prd-dashboard__care-plan--click',
  PRD_COURSE_DROPDOWN_CLICK: 'prd-course-dropdown--click',
  PRD_SECTION_DROPDOWN_CLICK: 'prd-section-dropdown--click',
  PRD_VIEW_ALL_COURSES_CLICK: 'prd-view-all-courses--click',
  PRD_DASHBOARD_CJMM_TAB_CLICK: 'prd-dashboard__cjmm-tab--click',
  PRD_DASHBOARD_CJMM_CLASS_ASSIGNMENT_VIEW_CLICK: 'prd-dashboard__cjmm-class-assignment-view--click',
  PRD_DASHBOARD_CJMM_CONSTRUCT_CLICK: 'prd-dashboard__cjmm-construct--click',
  PRD_DASHBOARD_CJMM_STUDENT_LIST_VIEW_CLICK: 'prd-dashboard__cjmm-student-list-view--click',
  PRD_DASHBOARD_CJMM_VIEW_STUDENT_DROPDOWN_CLICK: 'prd-dashboard__cjmm-view-student-dropdown--click',

  BENTO_BOX_CW_LINK_CLICK: 'bento_box__cw_link--click',
  BENTO_BOX_HAD_LINK_CLICK: 'bento_box__had_link--click',
  BENTO_BOX_EHR_LINK_CLICK: 'bento_box__ehr_link--click',
  BENTO_BOX_PRD_LINK_CLICK: 'bento_box__prd_link--click',
  BENTO_BOX_PS_LINK_CLICK: 'bento_box__ps_link--click',
  PAGE_HEADER_ACCESS_ALL_DASHBOARD_LINK_CLICK: 'page-header__access-all-dashboard-link__click',

  PS_COURSE_SUMMARY_HESI_REVIEW_ACTIVE_COHORTS_LINK_CLICK: 'ps-course-summary__hesi-review-active-cohorts-link--click',
  PS_COURSE_SUMMARY_SHERPATH_REVIEW_ACTIVE_COURSES_LINK_CLICK: 'ps-course-summary__sherpath-review-active-courses-link--click',
  PS_COURSE_SUMMARY_SHADOW_HEALTH_REVIEW_ACTIVE_COURSES_LINK_CLICK: 'ps-course-summary__shadow-health-review-active-courses-link--click',

  PS_STUDENT_PROFILE_BACK_BUTTON_CLICK: 'ps-student-profile__back-button--click',
  PS_STUDENT_PROFILE_VIEW_STUDENT_ON_STUDENT_NAME_CLICK: 'ps-student-profile__view-student-on-student-name--click',
  PS_STUDENT_PROFILE_SHADOW_HEALTH_ASSIGNMENTS_TAB_CLICK: 'ps-student-profile__shadow-health-assignments-tab--click',
  PS_STUDENT_PROFILE_SHERPATH_ASSIGNMENTS_TAB_CLICK: 'ps-student-profile__sherpath-assignments-tab--click',
  PS_STUDENT_PROFILE_HESI_EXAMS_TAB_CLICK: 'ps-student-profile__hesi-exams-tab--click',
  PS_STUDENT_PROFILE_CATEGORY_PERFORMANCE_TAB_CLICK: 'ps-student-profile__category-performance-tab--click',
  PS_STUDENT_PROFILE_NGN_DETAILS_TAB_CLICK: 'ps-student-profile__ngn-details-tab--click',
  PS_STUDENT_PROFILE_ADD_ADAPTIVE_QUIZ_BUTTON_CLICK: 'ps-student-profile__add-adaptive-quiz-button--click',
  PS_STUDENT_PROFILE_RESOURCES_AVAILABLE_BUTTON_CLICK: 'ps-student-profile__resources-available-button--click',
  PS_STUDENT_PROFILE_TOPIC_CARD_COLLAPSE_BUTTON_CLICK: 'ps-student-profile__topic-card-collapse-button--click',
  PS_STUDENT_PROFILE_TOPIC_RESOURCES_TABLE_ADD_BUTTON_CLICK: 'ps-student-profile__topic-resources-table-add-button--click',

  PS_LANDING_PAGE_HESI_COHORTS_CLICK: 'ps-landing-page__hesi-cohorts--click',
  PS_LANDING_PAGE_SHERPATH_REVIEW_ACTIVE_COURSES_LINK_CLICK: 'ps-landing-page__sherpath-review-active-courses-link--click',
  PS_LANDING_PAGE_SHADOW_HEALTH_REVIEW_ACTIVE_COURSES_LINK_CLICK: 'ps-landing-page__shadow-health-review-active-courses-link--click',
  PS_LANDING_PAGE_EXAM_DETAIL_PAGE_CLICK: 'ps-landing-page__exam-detail-page--click',
  PS_LANDING_PAGE_BACK_BUTTON_CLICK: 'ps-landing-page__back-button--click',
  PS_LANDING_PAGE_VIEW_STUDENT_ON_STUDENT_NAME_CLICK: 'ps-landing-page__view-student-on-student-name--click',
  PS_LANDING_PAGE_FILTER_MOST_RECENT_EXAMS_CLICK: 'ps-landing-page__filter-most-recent-exams--click',

  CRPA_BACK_TO_REFERRING_PAGE_CLICK: 'crpa__back-to-referring-page--click',
  CPRA_STUDENTS_BELLOW_PROFICIENT_CLICK: 'cpra__students-bellow-proficient--click',
  CPRA_ASSESSMENT_CLICK: 'cpra__assessment--click',
  CPRA_COMMUNICATION_CLICK: 'cpra__communication--click',
  CPRA_CLINICAL_JUDGMENT_CLICK: 'cpra__clinical-judgment--click',
  CPRA_EDUCATION_CLICK: 'cpra__education--click',
  CPRA_SAFETY_CLICK: 'cpra__safety--click',
  CPRA_STUDENT_NAME_SEARCH: 'cpra__student-name-search',
  CPRA_EMERGING_FILTER_CLICK: 'cpra__emerging-filter--click',
  CPRA_DEVELOPING_FILTER_CLICK: 'cpra__developing-filter--click',
  CPRA_PROFICIENT_FILTER_CLICK: 'cpra__proficient-filter--click',
  CPRA_ADVANCED_FILTER_CLICK: 'cpra__advanced-filter--click'
};

export const Language = {
  DEFAULT_LOCALE: 'en'
};

export const DATE_FORMAT = 'MM-DD-YYYY';
export const SHORT_DATE_FORMAT = 'M/D/YY';
export const DATE_FORMAT_SECONDARY = 'MMM YYYY';

export const TABLE_FILTER_DATE_FORMAT = 'MM/DD/YY';

/* format for react-datepicker require */
export const TABLE_FILTER_MONTH_YEAR_FORMAT = 'MM/yyyy';
export const TABLE_FILTER_MONTH_YEAR_DISPLAY_FORMAT = 'mm/yyyy';
export const TABLE_FILTER_DATE_EVENT = {
  startDateChange: 'startDateChange',
  endDateChange: 'endDateChange'
};

export const ALL_MENU_OPTION = 'all';

export const LOCALE_PLACEHOLDER = 'LOCALE_PLACEHOLDER';

export const X_TOKEN_COOKIE = 'x-token';

export const X_LINK_ID_COOKIE = 'x-link-id';

export const ADOBE_PRODUCT_CODE = {
  HAD_DASHBOARD: 'had',
  SHERPATH_COURSEWARE: 'scwp'
};

// TODO: revert new pendo key of INS-866, commented this line for later use
export const pendoAPIKey = 'd8168c6c-a23b-4081-6add-48a5809ba64f';
// export const pendoAPIKey = '53af6ed6-345d-472a-7063-45350c27de7c'; //old key -- keep until 2022/04/01

export const KEY_CODES = {
  UP: 38,
  DOWN: 40,
  ENTER: 13,
  TAB: 9,
  SPACE: 32,
  ESC: 27
};

export const PRESS_KEYS = {
  ESCAPE: 'Escape',
  TAB: 'Tab',
  DOWN: 'Down',
  UP: 'Up',
  SPACE: 'Space',
  ENTER: 'Enter'
};

export const SORT_DIRECTION = {
  ASC: 'asc',
  DESC: 'desc'
};

export enum SORT_DIRECTION_ENUM {
  asc = 'asc',
  desc = 'desc'
}

export const PAGE_NAMES = {
  ASSIGNMENT_SUMMARY: 'assignmentSummaryPage',
  GRADEBOOK: 'gradebookPage'
};

export const USER_ROLE = {
  EOLS_INSTRUCTOR: 'Ins',
  EVOLVE_INSTRUCTOR: 'faculty',
  STUDENT: 'Stu'
};

export const SYSTEM_ID = {
  EOLS: 'eols',
  EVOLVE: 'evo'
};

export const EAQ_ISBN = 'EPRODUCT';
export const HESI_EXAM = 'HESI_EXAM';

export const APP_DEFAULT_NAV_ITEMS: PageNavigation = {
  default: [
    { name: 'Performance Summary', paths: [RoutePath.home, RoutePath.cwIns] },
    { name: 'Gradebook', paths: [PerformancePath.gradeBook.home] },
    { name: 'Engagement', paths: [PerformancePath.engagementSummary, ...Object.values(PerformancePath.engagement)] },
    { name: 'Assignment Summary', paths: [PerformancePath.assignmentSummary] }
  ] as Array<NavItem>,
  expand: [] as Array<NavItem>,
  extraActive: [{ name: 'Disengaged Student', paths: [PerformancePath.disengagedStudent.home] }]
};

export const UI_EVENTS = {
  scrollToTable: 'scrollToTable'
};

export const STUDENT_DETAIL_APP_LINK = Object.freeze({
  CW: {
    title: 'View Sherpath Performance & Engagement',
    link: PerformancePath.studentDetail
  },
  HAD: {
    title: 'View HESI Readiness for NCLEX',
    link: HAD_PATHS?.studentDetail
  }
});

export const FULFILLED_STATUS = 'fulfilled';

export const REPORT_TITLE = {
  SHERPATH_PERFORMANCE_GRADES: {
    description: 'How are my students engaging with Sherpath learning?',
    title: 'Sherpath Performance & Grades Dashboard',
    backTo: 'Sherpath',
    disabledDescription: `This dashboard is only available to Sherpath users. If you are interested in using
    Sherpath for your nursing program contact your administrator or Elsevier Sales Representative`
  },
  HESI_SPECIALTY_EXAM_READINESS: {
    description: 'How are my students mastering EAQ in HESI Focus Chapters?',
    title: 'HESI Specialty Exam Readiness',
    backTo: 'Sherpath',
    disabledDescription: `This dashboard is only available to Sherpath and Elsevier Adaptive Quizzing users.
    If you are interested in using Sherpath or Elsevier Adaptive Quizzing for your nursing program contact your administrator or Elsevier Sales Representative`
  },
  HESI_NCLEX_READINESS: {
    description: 'How are my students progressing towards NCLEX readiness?',
    title: 'HESI Readiness for NCLEX',
    disabledDescription: `This dashboard is only available to HESI users. If you are interested in using HESI
    for your nursing program contact your administrator or Elsevier Sales Representative`
  },
  READINESS_DASHBOARD: {
    description: 'How are my students progressing toward practice readiness in Shadow Health?',
    title: 'Practice Readiness Dashboard',
    backTo: 'Shadow Health',
    disabledDescription: `This dashboard is only available to Shadow Health users. If you are interested in using Shadow Health
    for your program, please contact your administrator or Elsevier Sales Representative`
  }
};

export const EAB_EVOLVE_PRODUCT_TYPE_KEY = 'elsevier_assessment_builder_ia';

export const EAB_APP_NAME = 'Elsevier Assessment Builder';

export const CS_EVOLVE_PRODUCT_TYPE_KEY = 'clinicalskills_ia';

export const CS_APP_NAME = 'Clinical Skills';

export const CS_APP_TITLE = 'Clinical Skills Performance & Grades Dashboard';

export const HESI_BREADCRUMB_BACK_TITLE = 'HESI Readiness for NCLEX Dashboard';

export const PROGRESS_CHART_PERCENT_POSITION = Object.freeze({
  LEFT: 'LEFT',
  TOP: 'TOP'
});

export const SEARCH_PLACEHOLDER = Object.freeze({
  STUDENT_EXAM_RESULTS: 'Search for a student’s exam results',
  STUDENT_NAME: 'Search by student name',
  EXAM_NAME: 'Search by exam name',
  COURSE: 'Search by course',
  EXAM_GROUPS: 'Search exam groups',
  CHAPTER: 'Search by chapter',
  KEYWORD: 'Search by Keyword',
  CATEGORY: 'Search by Category',
  CATEGORY_NAME: 'Search by category name'
});

export const VALUE_NA_TEXT = 'n/a';

export const ROUTE_ROLES = {
  INSTRUCTOR: [USER_ROLE.EOLS_INSTRUCTOR, USER_ROLE.EVOLVE_INSTRUCTOR],
  STUDENT: [USER_ROLE.STUDENT],
  ALL: [USER_ROLE.EOLS_INSTRUCTOR, USER_ROLE.EVOLVE_INSTRUCTOR, USER_ROLE.STUDENT]
} as const;

export const APP_ID_VALUE = {
  SHER_EVOL: 'sher_evol',
  SHERPATH: 'sherpath',
  HESI: 'hesi'
};

export const SCORE_SCALE_CONSTANT = Object.freeze({
  MAX_AT_RISK: 750,
  MAX_BELOW_ACCEPTABLE: 850,
  MAX_ACCEPTABLE: 900
});

export const MAX_HESI_SCORE_ON_CHART = 1200;
export const MIN_HESI_SCORE_ON_CHART = 400;

export const LOADING_CONTENT = {
  COURSE_AGGREGATE: 'Loading your Course Data. This may take a moment',
  PERFORMANCE_SUMMARY: 'Loading your upcoming assignments. This may take a moment'
};

export const WELCOME_HEADER = 'Assess, Analyze, Achieve';

export const NOT_FOUND_FALLBACK_MESSAGE = 'Not found';

export const CLARITY_DATA_ICON_FEATURE_BY_DASHBOARD = {
  cw: FeatureFlagNames.CW_CLARITY_DATA_ICON_ENABLED,
  ehr: FeatureFlagNames.EHR_CLARITY_DATA_ICON_ENABLED,
  had: FeatureFlagNames.HAD_CLARITY_DATA_ICON_ENABLED,
  ps: FeatureFlagNames.PS_CLARITY_DATA_ICON_ENABLED,
  prd: FeatureFlagNames.PRD_CLARITY_DATA_ICON_ENABLED
};

export const SESSION_STORAGE_INDEXED_DB_KEY = 'indexedDBStorageName';
